import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import { Trans } from "react-i18next";
import { goTo } from "../utilities/utils";
import ClickButton from "./ClickButton";
import styled from "styled-components";
import BestOffersTextOverlay from "./BestOffersTextOverlay";
import CustomTextOverlay from "./CustomTextOverlay";

var bestOffers = [
  {
    offers: [
      {
        isGeneral: true,
        country: "Canada",
        type: "",
        title: "Canadian Plans",
        description:
          "Explore True North. The most affordable plans on the fastest network.",
        image: "/images/planImage4.png",
        textOverlay: null,
        addCustomText: false,
      },
      {
        isGeneral: true,
        country: "USA",
        type: "",
        title: "American Plans",
        description:
          "The best network. Never worry about overages with our prepaid plan",
        image: "/images/planImage5.png",
        textOverlay: null,
        addCustomText: false,
      },
      {
        country: "Canada",
        type: "",
        title: "Special eSIM Deals",
        description:
          "With our eSIM service, you can enjoy instant connectivity and constant network coverage.",
        image: "/images/banner-special-esim-deals.png",
        textOverlay: null,
        addCustomText: true,
      },
    ],
  },
  {
    offers: [
      {
        country: "Canada",
        type: "postpaid",
        title: "Monthly Plans",
        description:
          "Long Term Plan options without commitments or contracts. Great plan options for extended stays. Perfect for students!",
        image: "/images/planImage0.png",
        textOverlay: null,
        addCustomText: false,
      },
      {
        country: "Canada",
        type: "prepaid",
        title: "Prepaid Plans",
        description:
          "The perfect plan option for shorter visits. 30 day plans with the option to extend your service period.",
        image: "/images/planImage1.png",
        textOverlay: null,
        addCustomText: false,
      },
      {
        country: "Canada",
        type: "postpaid",
        title: "Our Best Deal!",
        description:
          "Check our most wanted plan! Try out and see why this is the favorite plan of our users!",
        image: "/images/Banner-Canada.png",
        textOverlay: {
          price: 35,
          data: 50,
        },
        addCustomText: false
      },
    ],
  },
  {
    offers: [
      {
        country: "USA",
        type: "prepaid",
        title: "Prepaid Plans",
        description:
          "The perfect plan option for shorter visits. 30 day plans with the option to extend your service period.",
        image: "/images/planImage1.png",
        textOverlay: null,
        addCustomText: false,
      },
      {
        country: "USA",
        type: "prepaid",
        title: "Our Best Deal!",
        description:
          "Check our latest plan options!",
        image: "/images/Banner-USA.png",
        textOverlay: {
          price: 15,
          data: 5,
        },
        addCustomText: false,
      },
    ],
  },
];

interface BestOffersProps {
  currentIndex: number;
}

const BestOffers: React.FC<BestOffersProps> = (props: BestOffersProps) => {
  return (
    <Row>
      {bestOffers[props.currentIndex].offers.map((item, i) => (
        <BestOffersCol md={props.currentIndex == 2 ? 6 : 4} sm={12} key={i}>
          <BestOffersCard style={{ position: "relative" }}>
            <Image
              width="273px"
              src={item.image}
              alt="planImage0"
              fluid
              style={{ borderRadius: "12px" }}
            />
            {item.textOverlay && (
              <BestOffersTextOverlay
                price={item.textOverlay.price}
                timeUnit="/month"
                data={item.textOverlay.data}
              />
            )}
            {item.addCustomText && item.title === 'Our Best Deal!' && (
              <CustomTextOverlay
                price={100}
                timeUnit="/month"
                data={100}
                type="OurBestDeal"
              />
            )}
            {item.addCustomText && item.title === 'Special eSIM Deals' && (
              <CustomTextOverlay
                price={100}
                timeUnit="/month"
                data={100}
                type="SpecialEsimDeal"
              />
            )}
            <BestOffersTitle>
              <Trans>{item.title}</Trans>
            </BestOffersTitle>
            <BestOffersDescription>
              <Trans>{item.description}</Trans>
            </BestOffersDescription>
            <BestOffersButton>
              <ClickButton
                text="Shop Now"
                color={""}
                length={"long"}
                buttonLength={""}
                onClick={() => {
                  window.localStorage.setItem("PlanDefaultType", item.type);
                  window.localStorage.setItem("country", item.country);
                  item.title === "Special eSIM Deals" ? window.open("https://esim.gophonebox.com") : goTo("/plans");
                }}
              />
            </BestOffersButton>
          </BestOffersCard>
        </BestOffersCol>
      ))}
    </Row>
  );
};

const BestOffersCol = styled(Col)`
  margin-top: 35px;
`;

const BestOffersCard = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 526px;
  justify-content: space-between;
`;

const BestOffersTitle = styled.div`
  text-align: center;
  font: normal normal 600 22px/33px Poppins;
  letter-spacing: 0px;
  color: #333333;
  margin-top: 35px;
  height: 150px;

  @media (max-width: 992px) {
    font-size: 18px;
  }
`;

const BestOffersDescription = styled.div`
  text-align: center;
  width: 16rem;
  font: normal normal normal 16px/20px Poppins;
  letter-spacing: 0.4px;
  color: #333333;
  margin-top: 4px;
  margin-bottom: 20px;
  height: 340px;
`;

const BestOffersButton = styled.div`
  margin-bottom: 105px;
`;

export default BestOffers;
