import React from "react";
import { Carousel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { constants } from "../../redux/constants";
import { updateShopObject } from "../../redux/actions/shopActions";
import MainCarouselCard from "../MainCarousel/MainCarouselCard";
import "./FeatureSlider.scss";
import { goTo } from "../../utilities/utils";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface FeatureSliderProps {
  currentIndex: number;
}

const FeatureSlider: React.FC<FeatureSliderProps> = (
  props: FeatureSliderProps
) => {
  const dispatch = useDispatch();

  const setCountry = (index) => {
    switch (index) {
      case 0:
      case 1:
        window.localStorage.setItem("country", constants.CANADA);
        dispatch(updateShopObject({ country: constants.CANADA }) as any);
        dispatch(
          updateShopObject({ currency: constants.CANADA_CURRENCY }) as any
        );
        break;
      case 2:
        window.localStorage.setItem("country", constants.USA);
        dispatch(updateShopObject({ country: constants.USA }) as any);
        dispatch(updateShopObject({ currency: constants.USA_CURRENCY }) as any);
        break;
    }
  };

  const { language } = useI18next();

  const getImageLanguage = () => {
    if (language === "en") {
      return "/images/mc/01-03-23/en.png";
    } else if (language === "fr") {
      return "/images/mc/01-03-23/fr.png";
    } else if (language === "sp") {
      return "/images/mc/01-03-23/sp.png";
    }
  };

  return (
    <Carousel
      className="feature-slider-carousel"
      indicators={null}
      interval={8000}
      nextIcon={null}
      prevIcon={null}
    >
      {props.currentIndex === 0 && [
        <Carousel.Item key={0}>
          <MainCarouselCard
            imageUrl={
              language === "en"
                ? "/images/mc/canada-plan.png"
                : "/images/mc/mc_canada_plans.png"
            }
            titleText={language === "en" ? "" : "Canadian Plans"}
            buttonText="Visit"
            style={{ margin: "30px" }}
            onClick={() => {
              setCountry(1);
              goTo("/plans");
            }}
          />
        </Carousel.Item>,
        <Carousel.Item key={1}>
          <MainCarouselCard
            imageUrl={
              language === "en"
                ? "/images/mc/usa-plan.png"
                : "/images/mc/mc_american_plans.png"
            }
            titleText={language === "en" ? "" : "American Plans"}
            buttonText="Visit"
            style={{ margin: "30px" }}
            onClick={() => {
              setCountry(2);
              goTo("/plans");
            }}
          />
        </Carousel.Item>,
      ]}
      {props.currentIndex === 1 && [
        <Carousel.Item key={2}>
          <MainCarouselCard
            imageUrl={`/images/mc/mc_maximize_your_data_${language}.png`}
            titleText="New Plans Available!"
            onClick={() => {
              goTo("/plans");
            }}
            style={{ margin: "30px" }}
            buttonText="Get It Now!"
          />
        </Carousel.Item>,
        <Carousel.Item key={3}>
          <MainCarouselCard
            imageUrl={`/images/mc/mc_esimdeal_${language}.png`}
            titleText="Digital SIM Card"
            showConnectInAFlash
            onClick={() => {
              goTo("https://esim.gophonebox.com/");
            }}
            style={{ margin: "30px" }}
            buttonText="Check It Out!"
          />
        </Carousel.Item>,
        <Carousel.Item key={4}>
          <MainCarouselCard
            imageUrl={`/images/mc/mc_new_travel_plan_${language}.png`}
            style={{ margin: "30px" }}
            titleText="Prepaid Plans"
            showPrepaidPlans
            onClick={() => {
              goTo(
                "https://blog.gophonebox.com/meet-phonebox-usa-the-best-telecom-service-now-in-america"
              );
            }}
            buttonText="Discover!"
          />
        </Carousel.Item>,
      ]}
      {props.currentIndex == 2 && [
        <Carousel.Item key={5}>
          <MainCarouselCard
            style={{ margin: "30px" }}
            imageUrl={
              language === "en"
                ? "/images/mc/us-poster-new-4.png"
                : "/images/mc/us-poster-new.png"
            }
            titleText={language === "en" ? "" : "New Features"}
            // subtitleText="Extra Calling Power! Add 500 minutes or Unlimited International Calls to your plan!"
            subtitleComponent={
              language === "en" ? null : (
                <div>
                  <p
                    style={{
                      fontWeight: 500,
                      paddingTop: "8px",
                      fontSize: "20px",
                    }}
                    className="m-0 px-3 text-white"
                  >
                    <Trans>Extra Calling Power!</Trans>
                  </p>
                  <div
                    style={{
                      height: "1px",
                      width: "160px",
                      margin: "10px auto 16px",
                      backgroundColor: "#ffffff",
                      borderRadius: "4px",
                    }}
                  ></div>
                  <p
                    style={{
                      color: "#ffffff",
                      fontSize: "13px",
                      width: "170px",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  >
                    <Trans>
                      Add 500 minutes or Unlimited International Calls to your
                      plan!
                    </Trans>
                  </p>
                </div>
              )
            }
            buttonText="Know More"
            onClick={() => {
              goTo(
                "https://blog.gophonebox.com/all-about-our-us-international-calls-feature/"
              );
            }}
          />
        </Carousel.Item>,
        <Carousel.Item key={6}>
          <MainCarouselCard
            imageUrl={
              language === "en"
                ? "/images/mc/us-poster-new-6.png"
                : "/images/mc/us-poster-new-5.png"
            }
            style={{ margin: "30px" }}
            titleText={language === "en" ? "" : "Budget Bliss!"}
            // subtitleText={"Unlock 5GB for just $15 for 30 days! —Affordable Connectivity!"}
            subtitleComponent={
              language === "en" ? null : (
                <div>
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#ffffff",
                      marginTop: 8,
                    }}
                  >
                    <Trans>Affordable Connectivity!</Trans>
                  </p>
                  <div
                    style={{
                      borderRadius: "16px",
                      background:
                        "linear-gradient(180deg, #83bf69 -30.21%, #335625 139.58%)",
                      width: "180px",
                      margin: "auto",
                      padding: "10px",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#ffffff",
                        margin: 0,
                      }}
                    >
                      <span style={{ fontWeight: "700", fontSize: "16px" }}>
                        5 GB{" "}
                      </span>
                      <Trans>for just</Trans>
                    </p>
                    <p
                      style={{
                        color: "#ffffff",
                        fontWeight: "bold",
                        fontSize: "16px",
                        marginTop: "8px",
                        marginBottom: 0,
                      }}
                    >
                      <span
                        style={{
                          lineHeight: 1,
                          color: "#fff",
                          fontSize: "48px",
                          fontWeight: "600",
                        }}
                      >
                        $15
                      </span>
                      /30 <Trans>days</Trans>
                    </p>
                  </div>
                </div>
              )
            }
            buttonText="I want it!"
            onClick={() => {
              goTo("/activation");
            }}
          />
        </Carousel.Item>,
        <Carousel.Item key={7}>
          <MainCarouselCard
            style={{ margin: "30px" }}
            imageUrl={
              language === "en"
                ? "/images/mc/us-poster-new-7.png"
                : "/images/mc/us-poster-new-3.png"
            }
            titleText={language === "en" ? "" : "Calling all travelers!"}
            // subtitleText="Calling all travelers! New 7 and 15 Day Plan!"
            subtitleComponent={
              language === "en" ? null : (
                <div>
                  <p
                    style={{
                      fontWeight: 500,
                      paddingTop: "8px",
                      fontSize: "18px",
                      color: "#223919",
                    }}
                    className="m-0 px-3"
                  >
                    <Trans>New 7 and 15 Day Plan!</Trans>
                  </p>
                  <p
                    style={{
                      color: "#ffffff",
                      border: "3px solid #ffffff",
                      backgroundColor: "#223919",
                      width: "180px",
                      padding: "10px 8px",
                      margin: "18px auto",
                      borderRadius: "40px",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    <span>3GB</span>
                    <span>$8</span>/7 <Trans>days</Trans>
                  </p>
                </div>
              )
            }
            buttonText="View Plans"
            onClick={() => {
              setCountry(2);
              goTo("/plans");
            }}
          />
        </Carousel.Item>,
      ]}
    </Carousel>
  );
};

export default FeatureSlider;
